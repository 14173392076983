import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close.js';
import axios from 'axios';
import translations from './terms.json'; // Asegúrate de que este archivo esté en la ruta correcta

function PrivacyModal({ language = 'es' }) {
  const [open, setOpen] = useState(false);
  const [userLanguage, setUserLanguage] = useState(language);

  useEffect(() => {
    const fetchUserLanguage = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const countryCode = response.data.country_code;

        switch (countryCode) {
          case 'BR':
            setUserLanguage('pr'); // Portugués para Brasil
            break;
          case 'IT':
            setUserLanguage('it'); // Italiano para Italia
            break;
          case 'US':
          case 'GB':
          case 'CA':
          case 'AU':
            setUserLanguage('en'); // Inglés para países de habla inglesa
            break;
          default:
            setUserLanguage('es'); // Español por defecto
            break;
        }
      } catch (error) {
        console.error("Error fetching the user's location", error);
        // Si hay un error, mantenemos el idioma por defecto en 'es'
      }
    };

    fetchUserLanguage();
  }, []); // Este efecto se ejecuta solo una vez al montar el componente

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        {translations.privacyStatement[userLanguage]}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="privacy-modal-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="privacy-modal-title">
          {translations.privacyStatement[userLanguage]}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6" gutterBottom>
            {translations.lastUpdated[userLanguage]}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {translations.responsibility[userLanguage]}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {translations.purposes[userLanguage]}
          </Typography>
          <ul className='terms-list'>
            <li>{translations.purpose1[userLanguage]}</li>
            <li>{translations.purpose2[userLanguage]}</li>
            <li>{translations.purpose3[userLanguage]}</li>
            <li>{translations.purpose4[userLanguage]}</li>
            <li>{translations.purpose5[userLanguage]}</li>
            <li>{translations.purpose6[userLanguage]}</li>
            <li>{translations.purpose7[userLanguage]}</li>
            <li>{translations.purpose8[userLanguage]}</li>
            <li>{translations.purpose9[userLanguage]}</li>
          </ul>
          <Typography variant="body1" gutterBottom>
            {translations.requestRights[userLanguage]}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {translations.personalDataTransfer[userLanguage]}
          </Typography>
          <ul className='terms-list'>
            {translations.recipientsList[userLanguage].map((recipient, index) => (
              <li key={index}>{recipient}</li>
            ))}
          </ul>
          <Typography variant="h6" gutterBottom>
            {translations.definitions[userLanguage]}
          </Typography>
          <ul className='terms-list'>
            <li>{translations.definitionA[userLanguage]}</li>
            <li>{translations.definitionB[userLanguage]}</li>
            <li>{translations.definitionC[userLanguage]}</li>
            <li>{translations.definitionD[userLanguage]}</li>
            <li>{translations.definitionE[userLanguage]}</li>
            <li>{translations.definitionF[userLanguage]}</li>
          </ul>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default PrivacyModal;

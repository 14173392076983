// PriceOptions.js
import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';

export const PriceOptions = ({ onPlanChange }) => {  // Recibe la función del parent
  const [tabValue, setTabValue] = useState('1year');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    onPlanChange(newValue);  // Notifica a Landing.js del cambio de plan
  };

  return (
    <div className="price-options-container">
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        centered
        sx={{
          backgroundColor: '#ffffff', 
          padding: '30px 0',
          '.MuiTabs-flexContainer': {
            justifyContent: 'center',
          },
          '.MuiTabs-indicator': {
            display: 'none',
          }
        }}
      >
        <Tab
          label="1 año"
          value="1year"
          sx={{
            backgroundColor: '#f2f2f2',
            border: '1px solid silver',
            boxSizing: 'border-box',
            minWidth: '140px',
            margin: '0 5px',
            borderRadius: '25px 0 0 25px',
            '&.Mui-selected': {
              backgroundColor: '#3DB5B9',
              color: 'white',
              borderBottom: '1px solid #3DB5B9',
            }
          }}
        />
        <Tab
          label="3 años"
          value="3years"
          sx={{
            backgroundColor: '#f2f2f2',
            border: '1px solid silver',
            boxSizing: 'border-box',
            minWidth: '140px',
            margin: '0 5px',
            '&.Mui-selected': {
              backgroundColor: '#3DB5B9',
              color: 'white',
              borderBottom: '1px solid #3DB5B9',
            }
          }}
        />
        <Tab
          label="De por vida"
          value="lifetime"
          sx={{
            backgroundColor: '#f2f2f2',
            border: '1px solid silver',
            boxSizing: 'border-box',
            minWidth: '140px',
            margin: '0 5px',
            borderRadius: '0 25px 25px 0',
            '&.Mui-selected': {
              backgroundColor: '#3DB5B9',
              color: 'white',
              borderBottom: '1px solid #3DB5B9',
            }
          }}
        />
      </Tabs>
    </div>
  );
}

export default PriceOptions;

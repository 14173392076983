import React from 'react';
import {Landing} from './Landing.js';
import { createTheme, ThemeProvider } from '@mui/material/styles/index.js';

import './App.css';

const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#3DB5B9',
    },
    secondary: {
      main: '#000000',
    },
    typography: {
      color: '#000000',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={outerTheme}>
      <Landing />
    </ThemeProvider>
  );
}

export default App;
